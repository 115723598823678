// import _ from 'lodash';

import * as Popper from '@popperjs/core'
window.Popper = Popper

import * as bootstrap from 'bootstrap'
// import 'bootstrap-icons/font/bootstrap-icons.css'

window.bootstrap = bootstrap;

import Tooltip from 'bootstrap/js/dist/tooltip'
import Popover from 'bootstrap/js/dist/popover'


import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

